import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUsersContext } from 'contexts/users';
import { Row, Col } from 'react-bootstrap';

export const DashboardPage: React.FC = () => {
  const { usersState } = useUsersContext();

  const navigate = useNavigate();
  const redirect = (page: string) => {
    if (page === 'import') {
      navigate('/import-products');
    } else {
      navigate('/woocommerce-config');
    }
  };

  const isApiConnected = (): boolean => {
    if (usersState.user.apiConnected) {
      return usersState.user.apiConnected;
    }

    return false;
  };

  return (
    <>
      <Row className="dashboard">
        <Col className="p-0">
          <Row className="dashboard-header">
            <h1 className="mb-4">Hej, {usersState.user.company}.</h1>
            <div>
              <b>Velkommen til IT Stack&apos;s Import Tool</b>
              <p>Husk altid at tage en backup af din webshop inden du påbegynder et import</p>
            </div>
          </Row>
          <Row className="dashboard-actions">
            {!isApiConnected() && (
              <Col className="dashboard-actions-btn">
                <button onClick={() => redirect('integration')}>
                  <p className="dashboard-actions-btn-title">Ny til Import Tool?</p>
                  <i className="fas fa-download" />
                  <p>Start din opsætning her</p>
                </button>
              </Col>
            )}
            {isApiConnected() && (
              <Col className="dashboard-actions-btn">
                <button onClick={() => redirect('import')}>
                  <p className="dashboard-actions-btn-title">Start import her!</p>
                  <i className="fas fa-download" />
                </button>
              </Col>
            )}
          </Row>
          <Row className="text-center">
            <a href="https://www.itstack.dk/handelsbetingelser/" target="_blank" rel="noreferrer">
              Vilkår for Import Tool
            </a>
          </Row>
          <Row className="dashboard-info">
            <h1>Mangler du en leverandør?</h1>
            <div>
              <p>Vi tilføjer løbende flere leverandører til vores Import Tool.</p>
              <p>
                Hvis du har en leverandør, som du gerne vil have med i vores Import Tool, kan du sende en mail til:
                <a href="mailto:mail@itstack.dk"> mail@itstack.dk</a>
              </p>
              <p>
                <strong>Bemærk!</strong> Vi foretrækker dataudtræk fra API, Excel / CSV, og XML.
              </p>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};
