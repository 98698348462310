import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from 'App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.scss';
import '@fortawesome/fontawesome-free/css/all.css';

if (process.env.REACT_APP_ENVIRONMENT === 'staging' || process.env.REACT_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: function (integrations) {
      return [
        ...integrations,
        Sentry.browserTracingIntegration({
          tracingOrigins: [`${process.env.REACT_APP_API_URL}`],
        }),
      ];
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    sampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
