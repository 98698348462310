import React from 'react';

import { UsersContextProvider } from 'contexts/users';
import { SelectedProductsProvider } from 'contexts/selected-products';
import { MainPage } from 'pages/main';

import type { Children } from './types';

const AppContextProvider: React.FC<Children> = ({ children }) => {
  return <UsersContextProvider>{children}</UsersContextProvider>;
};

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <SelectedProductsProvider>
        <MainPage />
      </SelectedProductsProvider>
    </AppContextProvider>
  );
};

export default App;
