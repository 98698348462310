import React, { ChangeEvent, useEffect, useState } from 'react';
import Select, { OnChangeValue, components, OptionProps, MultiValueGenericProps } from 'react-select';
import { ProductInterface, CategoryInterface, TagInterface } from 'pages/import-products/shared/types';
import { ProductEditProps } from './types';
import { useSelectedProducts } from 'contexts/selected-products';
import { dexieDB } from 'pages/import-products/shared/db';

const EditSimpleProducts: React.FC<React.PropsWithChildren<ProductEditProps>> = (props) => {
  const [isEditing, setIsEditing] = useState<keyof ProductInterface | undefined>(undefined);
  const [product, setProduct] = useState<ProductInterface | undefined | null>(props.product);
  const [inputWidth, setInputWidth] = useState<string>('');
  const [savedProduct, setSavedProduct] = useState<string>('');

  const onProductSelect = (tmpProduct: ProductInterface): void => {
    props.setProductUnderEdit(tmpProduct);
    props.setIsSimpleModalOpen(true);
  };

  const numberFormatter = Intl.NumberFormat('da-DK');
  const { selectedProducts, setSelectedProducts } = useSelectedProducts();

  const toggleProductSelection = (product: ProductInterface) => {
    const isProductSelected = selectedProducts.some((selected) => selected.vendor_products_id === product.vendor_products_id);

    if (isProductSelected) {
      setSelectedProducts((current) => current.filter((selected) => selected.vendor_products_id !== product.vendor_products_id));
    } else {
      setSelectedProducts((current) => [...current, product]);
    }
  };

  const customSelectOptions: React.FC<OptionProps<CategoryInterface | TagInterface, true>> = (props) => {
    return (
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
      </components.Option>
    );
  };

  const CustomMultiValueContainer: React.FC<MultiValueGenericProps<CategoryInterface, true>> = (props) => {
    const { selectProps, data } = props;
    const allValues = Array.isArray(selectProps.value) ? selectProps.value : [];
    const valueLimit = 1;
    const index = allValues?.findIndex((value) => value.id === data.id);

    if (index < valueLimit) {
      return <components.MultiValueContainer {...props} />;
    }

    if (index === valueLimit) {
      return <div className="m-0"> +{allValues.length - valueLimit}</div>;
    }

    return null;
  };

  const CategoryValues = () => {
    // eslint-disable-next-line react/prop-types
    const categories = props.product.vendor_products_category as (string | CategoryInterface)[];
    const shownCategories = categories
      .slice(0, 1)
      .map((category, index, array) => {
        const separator = index < array.length - 1 ? ', ' : '';
        return typeof category === 'object' ? category.name + separator : category + separator;
      })
      .join('');

    if (categories.length > 1) {
      return <p className="m-0">{`${shownCategories} +${categories.length - 1}`}</p>;
    } else {
      return <p className="m-0">{shownCategories}</p>;
    }
  };

  const TagValues = () => {
    // eslint-disable-next-line react/prop-types
    const tags = props.product.vendor_products_tags as (string | TagInterface)[];
    const shownTags = tags
      .slice(0, 1)
      .map((tag, index, array) => {
        const separator = index < array.length - 1 ? ', ' : '';
        return typeof tag === 'object' ? tag.name + separator : tag + separator;
      })
      .join('');

    if (tags.length > 1) {
      return <p className="m-0">{`${shownTags} +${tags.length - 1}`}</p>;
    } else {
      return <p className="m-0">{shownTags}</p>;
    }
  };

  const handleEditing = (key: keyof ProductInterface) => {
    setIsEditing(key);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
    setSavedProduct(event.target.id);
    setTimeout(() => {
      setSavedProduct('');
    }, 4000);

    handleProductSave();
    setIsEditing(undefined);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const id = (event.target as HTMLInputElement).id;
      setSavedProduct(id);
      setTimeout(() => {
        setSavedProduct('');
      }, 4000);
      handleProductSave();
      setIsEditing(undefined);
    }
  };

  const handleProductValueChanged = (field: keyof ProductInterface, value: ProductInterface[keyof ProductInterface], event?: ChangeEvent<HTMLInputElement>) => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }

      return {
        ...prevData,
        [field]: value,
      } as ProductInterface;
    });
    if (event) {
      setInputWidth(event.target.value);
    }
  };

  const handleEditCategories = (newValue: OnChangeValue<CategoryInterface, true>): void => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }
      return {
        ...prevData,
        vendor_products_category: newValue as CategoryInterface[],
      };
    });
  };

  const handleEditTags = (newValue: OnChangeValue<TagInterface, true>): void => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }
      return {
        ...prevData,
        vendor_products_tags: newValue as TagInterface[],
      };
    });
  };

  useEffect(() => {
    setProduct(props.product);
  }, [props.product]);

  const handleProductSave = async (): Promise<void> => {
    if (!product) {
      return;
    }

    await dexieDB.editedProducts.update(product.vendor_products_id, product);
  };

  const handleProductClick = (product: ProductInterface) => {
    toggleProductSelection(product);
  };

  const handleRemoveProduct = (product: ProductInterface) => {
    const confirmRemove = confirm('Er du sikker på at du vil fjerne produktet?');

    if (confirmRemove) {
      props.removeProduct(product);
    }
  };

  const getProductStatus = (): string => {
    if (props.product.vendor_products_status) {
      return props.product.vendor_products_status === 'draft' ? 'Kladde' : 'Udgiv';
    } else {
      return (props.product.vendor_products_status = 'draft');
    }
  };

  const isInStock = (): boolean => {
    if (props.product.vendor_products_stock) {
      return props.product.vendor_products_stock > 0;
    }
    return false;
  };

  const editableSrc = (field: string[] | string) => {
    if (Array.isArray(field)) {
      return field.some((field) => savedProduct === field) ? 'fa-check' : 'fa-pen';
    }
    return savedProduct === field ? 'fa-check' : 'fa-pen';
  };

  const editableClass = (field: string[] | string) => {
    if (Array.isArray(field)) {
      return `editable-icon ${field.some((field) => savedProduct === field) ? 'editable-icon-focus' : ''}`;
    }
    return `editable-icon ${savedProduct === field ? 'editable-icon-focus' : ''}`;
  };

  return (
    <>
      <td className="product-item product-item-checkbox" onClick={() => handleProductClick(props.product)}>
        <div className="edit-custom-checkbox justify-content-end">
          <span className="whitespace"></span>
          <label className="align-self-center pt-1">
            <input type="checkbox" onClick={(event) => event.stopPropagation()} />
            <span className="edit-custom-checkbox-check" aria-hidden="true">
              <i className={(selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id) ? '' : 'hidden') + ' fas fa-check'} />
            </span>
          </label>
        </div>
      </td>

      <td className="product-item" onClick={() => handleProductClick(props.product)}>
        {props.product.vendor_products_image && props.product.vendor_products_image.length !== 0 ? (
          <img height={50} src={props.product.vendor_products_image} alt="produkt billede" loading="lazy" />
        ) : null}
      </td>

      <td
        className="product-item product-item-editable d-none d-sm-table-cell"
        onClick={() => {
          handleEditing('vendor_products_sku');
        }}
      >
        <div className="multi-line-wrapper product-item-container">
          <div className="d-flex align-items-center">
            {isEditing === 'vendor_products_sku' ? (
              <>
                <input
                  id="vendor_products_sku"
                  type="text"
                  value={product?.vendor_products_sku}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                  onFocus={(e) => setInputWidth(e.target.value)}
                  style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                  autoFocus
                ></input>
                <i className={'editable-icon editable-icon-focus fas ' + editableSrc('vendor_products_sku')} />
              </>
            ) : (
              <>
                <div>
                  <label className="product-item-editable-label">{props.product.vendor_products_sku}</label>
                  <i className={editableClass('vendor_products_sku') + ' fas ' + editableSrc('vendor_products_sku')} />
                </div>
              </>
            )}
          </div>
          <small>{props.product.vendor_products_ean}</small>
        </div>
      </td>

      <td
        className="product-item product-item-editable"
        onClick={() => {
          handleEditing('vendor_products_name');
        }}
      >
        <div className="product-item-container">
          {isEditing === 'vendor_products_name' ? (
            <>
              <input
                id="vendor_products_name"
                type="text"
                value={product?.vendor_products_name}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                onFocus={(e) => setInputWidth(e.target.value)}
                style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                autoFocus
              ></input>
              <i className={'editable-icon editable-icon-focus fas ' + editableSrc('vendor_products_name')} />
            </>
          ) : (
            <>
              <label className="product-item-editable-label">{props.product.vendor_products_name}</label>
              <i className={editableClass('vendor_products_name') + ' fas ' + editableSrc('vendor_products_name')} />
            </>
          )}
        </div>
      </td>

      <td
        className="product-item product-item-editable d-none d-sm-table-cell"
        onClick={() => {
          handleEditing('vendor_products_category');
        }}
      >
        <div style={{ minWidth: '120px' }}>
          {isEditing === 'vendor_products_category' ? (
            <div className="product-item-container">
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    minWidth: '120px',
                  }),
                }}
                inputId="vendor_products_category"
                options={props.webshopCategories}
                placeholder="Vælg kategori"
                isSearchable={true}
                isMulti={true}
                getOptionValue={(option: CategoryInterface) => option.id}
                getOptionLabel={(option: CategoryInterface) => `${option.name}`}
                components={{
                  Option: customSelectOptions,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  MultiValueContainer: CustomMultiValueContainer,
                }}
                onChange={handleEditCategories}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                noOptionsMessage={() => `${'Ingen kategorier'}`}
                defaultValue={props.product.vendor_products_category as CategoryInterface[]}
                menuIsOpen
                autoFocus
              />
              <i className={'editable-icon editable-icon-focus fas ' + editableSrc('vendor_products_category')} />
            </div>
          ) : (
            <div className="product-item-container">
              <label className="product-item-editable-label">
                {props.product.vendor_products_category?.length ? (
                  <>{Array.isArray(props.product.vendor_products_category) ? <CategoryValues /> : 'Tilføj kategori'}</>
                ) : (
                  <p className="mb-0">Tilføj kategori</p>
                )}
              </label>
              <i className={editableClass('vendor_products_category') + ' fas ' + editableSrc('vendor_products_category')} />
            </div>
          )}
        </div>
      </td>

      <td
        className="product-item product-item-editable d-none d-lg-table-cell"
        onClick={() => {
          handleEditing('vendor_products_tags');
        }}
      >
        <div style={{ minWidth: '120px' }}>
          {isEditing === 'vendor_products_tags' ? (
            <div className="product-item-container">
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    minWidth: '120px',
                  }),
                }}
                inputId="vendor_products_tags"
                options={props.webshopTags}
                placeholder="Vælg varenøgleord"
                isSearchable={true}
                isMulti={true}
                getOptionValue={(option: TagInterface) => option.id}
                getOptionLabel={(option: TagInterface) => `${option.name}`}
                components={{
                  Option: customSelectOptions,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  MultiValueContainer: CustomMultiValueContainer,
                }}
                onChange={handleEditTags}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                noOptionsMessage={() => `${'Ingen varenøgleord'}`}
                defaultValue={props.product.vendor_products_tags as TagInterface[]}
                menuIsOpen
                autoFocus
              />
              <i className={'editable-icon editable-icon-focus fas ' + editableSrc('vendor_products_tags')} />
            </div>
          ) : (
            <div className="product-item-container">
              <label className="product-item-editable-label">
                {props.product.vendor_products_tags?.length ? (
                  <>{Array.isArray(props.product.vendor_products_tags) ? <TagValues /> : 'Tilføj varenøgleord'}</>
                ) : (
                  <p className="mb-0">Tilføj varenøgleord</p>
                )}
              </label>
              <i className={editableClass('vendor_products_tags') + ' fas ' + editableSrc('vendor_products_tags')} />
            </div>
          )}
        </div>
      </td>

      <td className="product-item product-item-editable d-sm-table-cell">
        <div className="product-item-container" style={{ minWidth: '100px' }}>
          <div className="multi-line-wrapper ">
            {props.product.vendor_products_sales_price ? (
              <>
                {isEditing === 'vendor_products_price' ? (
                  <input
                    id="vendor_products_price"
                    type="number"
                    value={product?.vendor_products_price}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                    onFocus={(e) => setInputWidth(e.target.value)}
                    style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                    autoFocus
                  />
                ) : (
                  <>
                    <label
                      className="product-item-editable-label"
                      onClick={() => {
                        handleEditing('vendor_products_price');
                      }}
                    >
                      <s>{numberFormatter.format(props.product.vendor_products_price)} DKK</s>
                    </label>
                  </>
                )}

                {isEditing === 'vendor_products_sales_price' ? (
                  <input
                    id="vendor_products_sales_price"
                    type="number"
                    value={product?.vendor_products_sales_price}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                    onFocus={(e) => setInputWidth(e.target.value)}
                    style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                    autoFocus
                  />
                ) : (
                  <>
                    <label
                      className="product-item-editable-label"
                      onClick={() => {
                        handleEditing('vendor_products_sales_price');
                      }}
                    >
                      {numberFormatter.format(props.product.vendor_products_sales_price)} DKK
                    </label>
                  </>
                )}
              </>
            ) : (
              <>
                {isEditing === 'vendor_products_price' || isEditing === 'vendor_products_sales_price' ? (
                  <>
                    {isEditing === 'vendor_products_price' ? (
                      <input
                        id={'vendor_products_price'}
                        type="number"
                        value={product?.vendor_products_price}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                        onFocus={(e) => setInputWidth(e.target.value)}
                        style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                        autoFocus
                      />
                    ) : (
                      <>
                        <label
                          className="product-item-editable-label"
                          onClick={() => {
                            handleEditing('vendor_products_price');
                          }}
                        >
                          <s>{numberFormatter.format(props.product.vendor_products_price)} DKK</s>
                        </label>
                        <input
                          id={'vendor_products_sales_price'}
                          type="number"
                          value={product?.vendor_products_sales_price}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                          onFocus={(e) => setInputWidth(e.target.value)}
                          style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                          autoFocus
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <label
                      className="product-item-editable-label"
                      onClick={() => {
                        handleEditing('vendor_products_price');
                      }}
                    >
                      {numberFormatter.format(props.product.vendor_products_price)} DKK
                    </label>
                    <label
                      className="product-item-editable-label add-sales"
                      onClick={() => {
                        handleEditing('vendor_products_sales_price');
                      }}
                    >
                      + Tilføj udsalgspris
                    </label>
                  </>
                )}
              </>
            )}
          </div>
          <i
            className={
              editableClass(['vendor_products_price', 'vendor_products_sales_price']) +
              ' fas ' +
              editableSrc(['vendor_products_price', 'vendor_products_sales_price'])
            }
          />
        </div>
      </td>

      <td
        className="product-item product-item-editable d-none d-sm-table-cell"
        onClick={() => {
          handleEditing('vendor_products_status');
        }}
      >
        <div className="product-item-container" style={{ minWidth: '70px' }}>
          {isEditing === 'vendor_products_status' ? (
            <>
              <select
                id="vendor_products_status"
                aria-label="status"
                onBlur={handleBlur}
                value={product?.vendor_products_status}
                onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value)}
                onFocus={(e) => setInputWidth(e.target.value)}
                style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                autoFocus
              >
                <option value="draft">Kladde</option>
                <option value="publish">Udgiv</option>
              </select>
              <i className={'editable-icon editable-icon-focus fas ' + editableSrc('vendor_products_status')} />
            </>
          ) : (
            <>
              <label className="product-item-editable-label">{getProductStatus()}</label>
              <i className={editableClass('vendor_products_status') + ' fas ' + editableSrc('vendor_products_status')} />
            </>
          )}
        </div>
      </td>

      <td
        className="product-item product-item-editable d-none d-xl-table-cell"
        onClick={() => {
          handleEditing('vendor_products_stock');
        }}
      >
        <div className="product-item-container" style={{ minWidth: '70px' }}>
          {isEditing === 'vendor_products_stock' ? (
            <>
              <input
                id="vendor_products_stock"
                type="number"
                value={product?.vendor_products_stock}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                onFocus={(e) => setInputWidth(e.target.value)}
                style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                autoFocus
              ></input>
              <i className={'editable-icon editable-icon-focus fas ' + editableSrc('vendor_products_stock')} />
            </>
          ) : (
            <>
              <label className="product-item-editable-label">
                <span className={isInStock() ? 'in-stock' : 'out-of-stock'}></span>
                {props.product.vendor_products_stock} stk.
              </label>
              <i className={editableClass('vendor_products_stock') + ' fas ' + editableSrc('vendor_products_stock')} />
            </>
          )}
        </div>
      </td>

      <td className="product-item text-center">
        <span className="product-item-edit-btn product-item-remove-btn" onClick={() => handleRemoveProduct(props.product)}>
          <i className="fas fa-trash" />
        </span>
        <span className="product-item-edit-btn" onClick={() => onProductSelect(props.product)}>
          <i className="fas fa-edit" />
        </span>
      </td>
    </>
  );
};

export default EditSimpleProducts;
