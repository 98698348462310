import React, { useEffect, useState } from 'react';
import { getVendors, createVendor, deleteVendor } from 'Api';
import { Vendor } from './types';
import { Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';

export const CsvImportPage: React.FC = () => {
  const [vendor, setVendor] = useState<Vendor | null>({});
  const [allVendors, setAllVendors] = useState<Vendor[]>([]);
  const [statusText, setStatusText] = useState('');
  const [status, setStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const vendors = async (): Promise<void> => {
      const repsonse = await getVendors();

      if (repsonse) {
        setAllVendors(repsonse.data);
      }
    };

    vendors();
  }, []);

  const handleProductValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let value: File | string;

    if (event.target.type === 'file') {
      if (event.target.files !== null) {
        value = event.target.files[0];
      }
    } else {
      value = event.target.value;
    }

    setVendor((prevValue) => ({
      ...prevValue,
      [event.target.id]: value,
    }));
  };

  const handleFormSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (vendor === undefined || vendor === null) {
      return;
    }

    const formData = new FormData();
    for await (const [key, value] of Object.entries(vendor)) {
      formData.append(key, value);
    }

    setLoading(true);

    const response = await createVendor(formData);

    if (response) {
      if (response.data.error) {
        setStatus(false);
        setStatusText(response.status + ' ' + response.statusText);
      } else {
        setAllVendors([...allVendors, response.data.vendor]);
        setStatus(true);
        setStatusText(response.status + ': ' + response.data.message + '. Vendor: ' + response.data.vendor.vendors_name + ' uploaded');
      }
    } else {
      setStatus(false);
      setStatusText('400: Bad Request');
    }

    setLoading(false);
  };

  const handleRemoveVendor = async (vendorId: number): Promise<void> => {
    const response = await deleteVendor(vendorId);

    if (response) {
      if (response.data.status) {
        setStatus(true);
        setAllVendors(allVendors.filter((vendor) => vendor.vendors_id !== response.data.vendor.vendors_id));
        setStatusText(response.status + ': ' + response.data.message + '. Vendor: ' + response.data.vendor.vendors_name + ' deleted');
      } else {
        setStatus(false);
        setStatusText(response.status + ' ' + response.statusText);
      }
    }
  };

  return (
    <Row className="csv-import">
      <Col className="p-0">
        <div>
          <h1 className="csv-import-header">CSV Import</h1>
          <hr className="mt-2" />
        </div>
        <div className="mt-4">
          <b>Tilføj leverandør til Import Tool</b>
        </div>

        <Form className="csv-import-form" encType="multipart/form-data" acceptCharset="utf-8" onSubmit={handleFormSubmit}>
          <Form.Group className="csv-import-form-input" controlId="vendors_name">
            <Form.Label>Leverandør navn</Form.Label>
            <Form.Control type="text" placeholder="Indtast leverandør navn" required onChange={handleProductValueChanged} />
          </Form.Group>

          <Form.Group className="csv-import-form-input" controlId="vendors_image_url">
            <Form.Label>Leverandør logo</Form.Label>
            <Form.Control size="sm" type="file" required onChange={handleProductValueChanged} />
          </Form.Group>

          <Form.Group className="csv-import-form-input" controlId="vendors_industry">
            <Form.Label>Branche</Form.Label>
            <Form.Control type="text" placeholder="Indtast branche" required onChange={handleProductValueChanged} />
            <Form.Text className="text-muted">Indtast navn på branche</Form.Text>
          </Form.Group>

          <Form.Group controlId="formFile" className="csv-import-form-input">
            <Form.Label>Upload CSV fil</Form.Label>
            <Form.Control size="sm" type="file" onChange={handleProductValueChanged} required />
          </Form.Group>

          <Button className="csv-import-form-btn" type="submit">
            {loading ? (
              <>
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </>
            ) : (
              'Upload'
            )}
          </Button>
        </Form>

        <div className="mt-4">
          {loading ? (
            <>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          ) : (
            statusText && <span className={`csv-import-status ${status ? 'success' : 'failed'}`}>{statusText}</span>
          )}
        </div>

        <div className="mt-4">
          <div>
            <h1 className="csv-import-header">Liste over leverandører</h1>
            <hr className="mt-2" />
          </div>
          <Row className="csv-import-vendor">
            {allVendors.map((vendorData) => (
              <Col key={vendorData.vendors_id}>
                <Card className="csv-import-vendor-card">
                  <Card.Body>
                    <div className="csv-import-vendor-card-btn">
                      <Card.Title>{vendorData.vendors_name}</Card.Title>
                      {vendorData.vendors_import_type === 'csv' ? (
                        <button onClick={() => handleRemoveVendor(vendorData.vendors_id ? vendorData.vendors_id : 0)}>
                          <span>Slet</span>
                          <i className="fas fa-trash" />
                        </button>
                      ) : (
                        'API'
                      )}
                    </div>
                    <Card.Img variant="top" src={vendorData.vendors_image_url} alt="alt" />
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Col>
    </Row>
  );
};
