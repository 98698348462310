import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { getLocalJwtToken, removeLocalJwtToken, setLocalJwtToken, decodeJwtToken, tokenExpired } from 'lib/jwt';
import { UsersActions, UsersAction, UsersContextInterface } from './types';

import type { Children } from '../../types';

const defaultState = {
  user: {
    id: 0,
    company: '',
    email: '',
    role: [''],
    exp: 0,
  },
  token: '',
};

export const userReducer = (usersPayload: UsersContextInterface, action: UsersAction): UsersContextInterface => {
  const { type, payload } = action;
  switch (type) {
    case UsersActions.LOGIN: {
      setLocalJwtToken(payload);
      const jwtPayload = decodeJwtToken(payload);

      return {
        ...usersPayload,
        token: payload,
        user: jwtPayload,
      };
    }
    case UsersActions.LOGOUT: {
      removeLocalJwtToken();

      return {
        ...usersPayload,
        ...defaultState,
      };
    }
    default:
      return usersPayload;
  }
};

export const UsersContext = createContext<{
  usersState: UsersContextInterface;
  usersDispatch: React.Dispatch<UsersAction>;
}>({
  usersState: defaultState,
  usersDispatch: () => null,
});

export const UsersContextProvider: React.FC<Children> = ({ children }) => {
  const [usersState, usersDispatch] = useReducer(userReducer, defaultState);

  useEffect(() => {
    const localJwt = getLocalJwtToken();

    if (localJwt !== null) {
      usersDispatch({ type: UsersActions.LOGIN, payload: localJwt });

      const isExpired = tokenExpired(localJwt);
      if (isExpired === true) {
        usersDispatch({ type: UsersActions.LOGOUT, payload: '' });
      }
    }
  }, []);

  return <UsersContext.Provider value={{ usersState, usersDispatch }}>{children}</UsersContext.Provider>;
};

export const useUsersContext = (): {
  usersState: UsersContextInterface;
  usersDispatch: React.Dispatch<UsersAction>;
} => {
  return useContext(UsersContext);
};
