import React, { useEffect, useState } from 'react';

import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from './shared/db';

//Pages
import { SelectVendorPage } from './sub-pages/select-vendor';
import { SelectCategoriesPage } from './sub-pages/select-categories';
import { SelectProductsPage } from './sub-pages/select-products';
import { EditProductsPage } from './sub-pages/edit-products';
import { ConfirmImportPage } from './sub-pages/confirm-import';

import { Button, Col, Row, Card, Container } from 'react-bootstrap';

export const ImportProductsPage: React.FC = () => {
  const [currentSubPage, setCurrentSubPage] = useState(localStorage.getItem('import-tool-currentSubPage') || 'select-vendor');
  const [canContinue, setCanContinue] = useState<string>(localStorage.getItem('import-tool-canContinue') || 'false');

  const steps = [
    { id: 'select-vendor', label: 'Forhandler' },
    { id: 'select-categories', label: 'Kategori' },
    { id: 'select-products', label: 'Produkter' },
    { id: 'edit-products', label: 'Rediger' },
    { id: 'confirm-import', label: 'Godkend' },
  ];

  useEffect(() => {
    localStorage.setItem('import-tool-currentSubPage', currentSubPage);
  }, [currentSubPage]);

  useEffect(() => {
    if (canContinue) {
      localStorage.setItem('import-tool-canContinue', `${canContinue}`);
    }
  }, [canContinue]);

  const vendorCount: number | undefined = useLiveQuery(() => dexieDB.vendors.count());

  const categoriesCount: number | undefined = useLiveQuery(() => dexieDB.categories.count());

  const selectProductsCount: number | undefined = useLiveQuery(() => dexieDB.selectedProducts.count());

  // Handle prev & next button is-disabled
  useEffect(() => {
    switch (currentSubPage) {
      case 'select-vendor':
        if (vendorCount && vendorCount !== undefined) {
          setCanContinue(`${vendorCount > 0}`);
        } else {
          setCanContinue(`false`);
        }
        break;
      case 'select-categories':
        if (categoriesCount && categoriesCount !== undefined) {
          setCanContinue(`${categoriesCount > 0}`);
        } else {
          setCanContinue(`false`);
        }
        break;
      case 'select-products':
        if (selectProductsCount && selectProductsCount !== undefined) {
          setCanContinue(`${selectProductsCount > 0}`);
        } else {
          setCanContinue(`false`);
        }
        break;
      case 'edit-products':
        setCanContinue(`true`);
        break;
      default:
        setCanContinue(`false`);
    }
  }, [currentSubPage, canContinue, vendorCount, categoriesCount, selectProductsCount]);

  const handleNextPageRequest = () => {
    switch (currentSubPage) {
      case 'select-vendor':
        setCurrentSubPage('select-categories');
        break;
      case 'select-categories':
        setCurrentSubPage('select-products');
        break;
      case 'select-products':
        setCurrentSubPage('edit-products');
        break;
      case 'edit-products':
        setCurrentSubPage('confirm-import');
        break;
      default:
        break;
    }
  };

  const handlePreviousPageRequest = () => {
    switch (currentSubPage) {
      case 'select-categories':
        setCurrentSubPage('select-vendor');
        break;
      case 'select-products':
        setCurrentSubPage('select-categories');
        break;
      case 'edit-products':
        setCurrentSubPage('select-products');
        break;
      case 'confirm-import':
        if (vendorCount && vendorCount > 0 && categoriesCount && categoriesCount > 0 && selectProductsCount && selectProductsCount > 0) {
          setCurrentSubPage('edit-products');
        } else {
          setCurrentSubPage('select-vendor');
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="import">
      <Row>
        <div className="steps d-flex mt-4 mb-4">
          {steps.map((step, index) => {
            const isActive = steps.findIndex((s) => s.id === currentSubPage) >= index;

            return (
              <React.Fragment key={step.id}>
                <div className={`step-wrapper ${currentSubPage === step.id ? 'active' : ''}`}>
                  <span className={`step ${isActive ? 'active' : ''}`}>{index + 1}</span>
                  <p>{step.label}</p>
                </div>
                {index < steps.length - 1 && (
                  <div className="arrow-wrapper">
                    <span className={`arrow ${isActive ? 'active' : ''}`}></span>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </Row>

      <Row>
        <Col sm={12}>
          {(() => {
            switch (currentSubPage) {
              case 'select-vendor':
                return <SelectVendorPage />;
              case 'select-categories':
                return <SelectCategoriesPage />;
              case 'select-products':
                return <SelectProductsPage />;
              case 'edit-products':
                return <EditProductsPage />;
              case 'confirm-import':
                return <ConfirmImportPage />;
              default:
                return <p>you are not supposed to be here &apos;{currentSubPage}&apos;</p>;
            }
          })()}
        </Col>
      </Row>

      <Container className="footer-navigation">
        <Row className="justify-content-center">
          <Col sm={6} lg={4}>
            <Card>
              <Card.Body>
                <Row className="m-2">
                  <Col sm={12}>
                    <Row>
                      <Col xs={6}>
                        {currentSubPage !== 'select-vendor' && (
                          <Button className="button-orange previous" onClick={handlePreviousPageRequest}>
                            <i className="fas fa-chevron-left me-2" />
                            Forrige
                          </Button>
                        )}
                      </Col>
                      <Col xs={6}>
                        {currentSubPage !== 'confirm-import' && (
                          <Button className="next" onClick={handleNextPageRequest} disabled={canContinue === 'false'}>
                            Næste
                            <i className="fas fa-chevron-right ms-2" />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
