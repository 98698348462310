import React, { ChangeEvent, useEffect, useState } from 'react';
import { ProductInterface, CategoryInterface, ChildVariation, TagInterface } from 'pages/import-products/shared/types';
import { VariableEditProps } from './types';
import { useSelectedProducts } from 'contexts/selected-products';
import Select, { components, MultiValueGenericProps, OnChangeValue, OptionProps } from 'react-select';
import { dexieDB } from 'pages/import-products/shared/db';

const EditVariableProducts: React.FC<React.PropsWithChildren<VariableEditProps>> = (props) => {
  const numberFormatter = Intl.NumberFormat('da-DK');
  const { selectedProducts, setSelectedProducts } = useSelectedProducts();
  const [selectedVariations, setSelectedVariations] = useState<number[]>([]);
  const [isEditing, setIsEditing] = useState<{ key: keyof ProductInterface | keyof ChildVariation; id: number | undefined } | undefined>(undefined);
  const [product, setProduct] = useState<ProductInterface | undefined | null>(props.product);
  const [variationProducts, setVariationProducts] = useState<ChildVariation[] | undefined | null>(props.product.product_variations);
  const [inputWidth, setInputWidth] = useState<string>('');
  const [savedProduct, setSavedProduct] = useState<string>('');
  const [savedVariation, setSavedVariation] = useState<{ field: string; variation: number } | undefined>(undefined);
  const [showVariations, setShowVariations] = useState<boolean>(false);

  useEffect(() => {
    if (!props.product.product_variations) {
      return;
    }
    if (selectedProducts.some((product) => product.vendor_products_id === props.product.vendor_products_id)) {
      const variationIds = props.product.product_variations
        .map((variation) => variation.vendor_product_variations_id)
        .filter((id): id is number => id !== undefined);

      setSelectedVariations(variationIds);
    } else {
      setSelectedVariations([]);
    }
  }, [selectedProducts, props.product]);

  const displayPricesOnMainVariant = () => {
    if (props.product.product_variations) {
      const variantPrices: number[] = props.product.product_variations.map((variant) => {
        return variant.vendor_product_variations_price;
      });

      if (variantPrices && variantPrices.length > 0) {
        const lowestPrice = Math.min(...variantPrices);
        const highestPrice = Math.max(...variantPrices);

        if (lowestPrice === highestPrice) {
          return `${numberFormatter.format(lowestPrice)} DKK`;
        } else {
          return `Fra ${numberFormatter.format(lowestPrice)} DKK`;
        }
      }
    }
  };

  const displayStockOnMainVariant = () => {
    if (props.product.product_variations) {
      const variantStock = props.product.product_variations
        .map((variant) => Number(variant.vendor_product_variations_stock))
        .filter((stock) => stock !== undefined);
      if (variantStock.length > 0) {
        const outOfStock = variantStock.every((stock) => stock === 0);
        const allInStock = variantStock.every((stock) => stock > 0);

        if (outOfStock) {
          return <span className="out-of-stock"></span>;
        }

        if (allInStock) {
          return <span className="in-stock"></span>;
        }

        return <span className="stock-split"></span>;
      }
    }
  };

  const handleProductClick = (product: ProductInterface) => (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const isProductSelected = selectedProducts.some((selected) => selected.vendor_products_id === product.vendor_products_id);

    setSelectedProducts((currentProducts) =>
      isProductSelected ? currentProducts.filter((selected) => selected.vendor_products_id !== product.vendor_products_id) : [...currentProducts, product],
    );
  };

  const handleVariationClick = (variation: ChildVariation) => (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const variationId = variation.vendor_product_variations_id;
    if (variationId === undefined) return;

    setSelectedVariations((currentVariations) =>
      currentVariations.includes(variationId) ? currentVariations.filter((id) => id !== variationId) : [...currentVariations, variationId],
    );
  };

  const onProductSelect = (tmpProduct: ProductInterface): void => {
    props.setProductUnderEdit(tmpProduct);
    props.setIsSimpleModalOpen(true);
  };

  const customSelectOptions: React.FC<OptionProps<CategoryInterface | TagInterface, true>> = (props) => {
    return (
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
      </components.Option>
    );
  };

  const CustomMultiValueContainer: React.FC<MultiValueGenericProps<CategoryInterface, true>> = (props) => {
    const { selectProps, data } = props;
    const allCategories = Array.isArray(selectProps.value) ? selectProps.value : [];
    const valueLimit = 1;
    const index = allCategories?.findIndex((category) => category.id === data.id);

    if (index < valueLimit) {
      return <components.MultiValueContainer {...props} />;
    }

    if (index === valueLimit) {
      return <div className="m-0"> +{allCategories.length - valueLimit}</div>;
    }

    return null;
  };

  const CategoryValues = () => {
    // eslint-disable-next-line react/prop-types
    const categories = props.product.vendor_products_category as (string | CategoryInterface)[];
    const shownCategories = categories
      .slice(0, 1)
      .map((category, index, array) => {
        const separator = index < array.length - 1 ? ', ' : '';
        return typeof category === 'object' ? category.name + separator : category + separator;
      })
      .join('');

    if (categories.length > 1) {
      return <p className="m-0">{`${shownCategories} +${categories.length - 1}`}</p>;
    } else {
      return <p className="m-0">{shownCategories}</p>;
    }
  };

  const TagValues = () => {
    // eslint-disable-next-line react/prop-types
    const tags = props.product.vendor_products_tags as (string | TagInterface)[];
    const shownTags = tags
      .slice(0, 1)
      .map((tag, index, array) => {
        const separator = index < array.length - 1 ? ', ' : '';
        return typeof tag === 'object' ? tag.name + separator : tag + separator;
      })
      .join('');

    if (tags.length > 1) {
      return <p className="m-0">{`${shownTags} +${tags.length - 1}`}</p>;
    } else {
      return <p className="m-0">{shownTags}</p>;
    }
  };

  const handleEditing = (key: keyof ProductInterface | keyof ChildVariation, id?: number) => {
    if (id) {
      setIsEditing({ key, id });
    } else {
      setIsEditing({ key, id: undefined });
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>, variation?: ChildVariation) => {
    if (variation?.vendor_product_variations_id) {
      const field = (event.target as HTMLInputElement).id;
      setSavedVariation({ field, variation: variation.vendor_product_variations_id });
      setTimeout(() => {
        setSavedVariation(undefined);
      }, 4000);
    } else {
      const id = (event.target as HTMLInputElement).id;
      setSavedProduct(id);
      setTimeout(() => {
        setSavedProduct('');
      }, 4000);
    }

    handleProductSave();
    setIsEditing(undefined);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, variation?: ChildVariation) => {
    if (event.key === 'Enter') {
      if (variation?.vendor_product_variations_id) {
        const field = (event.target as HTMLInputElement).id;
        setSavedVariation({ field, variation: variation.vendor_product_variations_id });
        setTimeout(() => {
          setSavedVariation(undefined);
        }, 4000);
      } else {
        const id = (event.target as HTMLInputElement).id;
        setSavedProduct(id);
        setTimeout(() => {
          setSavedProduct('');
        }, 4000);
      }

      handleProductSave();
      setIsEditing(undefined);
    }
  };

  const handleProductValueChanged = (field: keyof ProductInterface, value: ProductInterface[keyof ProductInterface], event?: ChangeEvent<HTMLInputElement>) => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }

      return {
        ...prevData,
        [field]: value,
      } as ProductInterface;
    });
    if (event) {
      setInputWidth(event.target.value);
    }
  };

  const handleVariationValueChanged = (
    field: keyof ChildVariation,
    value: ChildVariation[keyof ChildVariation],
    selectedVariation: ChildVariation,
    event?: ChangeEvent<HTMLInputElement>,
  ) => {
    if (event) {
      setInputWidth(event.target.value);
    }
    setVariationProducts((prevData) => {
      if (!Array.isArray(prevData)) {
        return prevData;
      }

      const updatedVariations = prevData.map((variation) => {
        if (variation.vendor_product_variations_id === selectedVariation.vendor_product_variations_id) {
          return {
            ...variation,
            [field]: value,
          };
        }

        return variation;
      });

      handleProductValueChanged('product_variations', updatedVariations);
      return updatedVariations;
    });
  };

  const handleEditCategories = (newValue: OnChangeValue<CategoryInterface, true>): void => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }
      return {
        ...prevData,
        vendor_products_category: newValue as CategoryInterface[],
      };
    });
  };

  const handleEditTags = (newValue: OnChangeValue<TagInterface, true>): void => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }
      return {
        ...prevData,
        vendor_products_tags: newValue as TagInterface[],
      };
    });
  };

  const handleProductSave = async (): Promise<void> => {
    if (!product) {
      return;
    }

    await dexieDB.editedProducts.update(product.vendor_products_id, product);
  };

  useEffect(() => {
    setProduct(props.product);
    setVariationProducts(props.product.product_variations);
  }, [props.product]);

  const handleRemoveProduct = (product: ProductInterface, variation?: ChildVariation) => {
    const confirmRemove = confirm('Er du sikker på at du vil fjerne produktet?');

    if (confirmRemove) {
      props.removeProduct(product, variation);
    }
  };

  const getProductStatus = (): string => {
    if (props.product.vendor_products_status) {
      return props.product.vendor_products_status === 'draft' ? 'Kladde' : 'Udgiv';
    } else {
      return (props.product.vendor_products_status = 'draft');
    }
  };

  const editableSrc = (field: string[] | string, variation?: number) => {
    if (variation) {
      if (Array.isArray(field)) {
        return field.some((field) => savedVariation?.field === field) && savedVariation?.variation === variation ? 'fa-check' : 'fa-pen';
      }
      return savedVariation?.field === field && savedVariation.variation === variation ? 'fa-check' : 'fa-pen';
    }
    return savedProduct === field ? 'fa-check' : 'fa-pen';
  };

  const editableClass = (field: string[] | string, variation?: number) => {
    if (variation) {
      if (Array.isArray(field)) {
        return `editable-icon ${
          field.some((field) => savedVariation?.field === field) && savedVariation?.variation === variation ? 'editable-icon-focus' : ''
        }`;
      }
      return `editable-icon ${savedVariation?.field === field && savedVariation.variation === variation ? 'editable-icon-focus' : ''}`;
    }
    return `editable-icon ${savedProduct === field ? 'editable-icon-focus' : ''}`;
  };

  return (
    <>
      <tr
        className={`${selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id) ? 'product-table-selected' : ''} ${
          showVariations ? 'product-table-variable' : ''
        }`}
      >
        <td className="product-item product-item-checkbox" onClick={handleProductClick(props.product)}>
          <div className="edit-custom-checkbox justify-content-end">
            {props.product.product_variations && props.product.product_variations?.length !== 0 && (
              <label
                onClick={(event) => {
                  event.stopPropagation();
                  setShowVariations(!showVariations);
                }}
                className="align-self-center product-item-expand"
              >
                <span>
                  <i className={`fas fa-${showVariations ? 'angle-up' : 'angle-down'}`} />
                </span>
              </label>
            )}
            <label className="align-self-center pt-1">
              <input type="checkbox" onClick={(event) => event.stopPropagation()} />
              <span className="edit-custom-checkbox-check" aria-hidden="true">
                <i className={(selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id) ? '' : 'hidden') + ' fas fa-check'} />
              </span>
            </label>
          </div>
        </td>

        <td className="product-item" onClick={handleProductClick(props.product)}>
          <img height={50} src={props.product.vendor_products_image} alt="produkt billede" loading="lazy" />
        </td>

        <td className="product-item d-none d-sm-table-cell">
          <div className="multi-line-wrapper product-item-container">
            <label>
              {props.product.vendor_products_sku ? props.product.vendor_products_sku : props.product.product_variations?.[0].vendor_product_variations_sku}
            </label>
            <small>
              {props.product.vendor_products_ean ? props.product.vendor_products_ean : props.product.product_variations?.[0].vendor_product_variations_ean}
            </small>
          </div>
        </td>

        <td
          className="product-item product-item-editable"
          onClick={() => {
            handleEditing('vendor_products_name');
          }}
        >
          <div className="product-item-container">
            {isEditing && isEditing.key === 'vendor_products_name' ? (
              <>
                <input
                  id="vendor_products_name"
                  type="text"
                  value={product?.vendor_products_name}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                  onFocus={(e) => setInputWidth(e.target.value)}
                  style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                  autoFocus
                ></input>
                <i className={'editable-icon editable-icon-focus fas ' + editableSrc('vendor_products_name')} />
              </>
            ) : (
              <>
                <label className="product-item-editable-label">{props.product.vendor_products_name}</label>
                <i className={editableClass('vendor_products_name') + ' fas ' + editableSrc('vendor_products_name')} />
              </>
            )}
          </div>
        </td>

        <td
          className="product-item product-item-editable d-none d-sm-table-cell"
          onClick={() => {
            handleEditing('vendor_products_category');
          }}
        >
          <div style={{ minWidth: '120px' }}>
            {isEditing && isEditing.key === 'vendor_products_category' ? (
              <div className="product-item-container">
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      minWidth: '120px',
                    }),
                  }}
                  inputId="vendor_products_category"
                  options={props.webshopCategories}
                  placeholder="Vælg kategori"
                  isSearchable={true}
                  isMulti={true}
                  getOptionValue={(option: CategoryInterface) => option.id}
                  getOptionLabel={(option: CategoryInterface) => `${option.name}`}
                  components={{
                    Option: customSelectOptions,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    MultiValueContainer: CustomMultiValueContainer,
                  }}
                  onChange={handleEditCategories}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  noOptionsMessage={() => `${'Ingen kategorier'}`}
                  defaultValue={props.product.vendor_products_category as CategoryInterface[]}
                  menuIsOpen
                  autoFocus
                />
                <i className={'editable-icon editable-icon-focus fas ' + editableSrc('vendor_products_category')} />
              </div>
            ) : (
              <div className="product-item-container">
                <label className="product-item-editable-label">
                  {props.product.vendor_products_category?.length ? (
                    <>{Array.isArray(props.product.vendor_products_category) ? <CategoryValues /> : 'Tilføj kategori'}</>
                  ) : (
                    <p className="mb-0">Tilføj kategori</p>
                  )}
                </label>
                <i className={editableClass('vendor_products_category') + ' fas ' + editableSrc('vendor_products_category')} />
              </div>
            )}
          </div>
        </td>

        <td
          className="product-item product-item-editable d-none d-lg-table-cell"
          onClick={() => {
            handleEditing('vendor_products_tags');
          }}
        >
          <div style={{ minWidth: '120px' }}>
            {isEditing && isEditing.key === 'vendor_products_tags' ? (
              <div className="product-item-container">
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      minWidth: '120px',
                    }),
                  }}
                  inputId="vendor_products_tags"
                  options={props.webshopTags}
                  placeholder="Vælg varenøgleord"
                  isSearchable={true}
                  isMulti={true}
                  getOptionValue={(option: TagInterface) => option.id}
                  getOptionLabel={(option: TagInterface) => `${option.name}`}
                  components={{
                    Option: customSelectOptions,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    MultiValueContainer: CustomMultiValueContainer,
                  }}
                  onChange={handleEditTags}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  noOptionsMessage={() => `${'Ingen varenøgleord'}`}
                  defaultValue={props.product.vendor_products_tags as TagInterface[]}
                  menuIsOpen
                  autoFocus
                />
                <i className={'editable-icon editable-icon-focus fas ' + editableSrc('vendor_products_tags')} />
              </div>
            ) : (
              <div className="product-item-container">
                <label className="product-item-editable-label">
                  {props.product.vendor_products_tags?.length ? (
                    <>{Array.isArray(props.product.vendor_products_tags) ? <TagValues /> : 'Tilføj varenøgleord'}</>
                  ) : (
                    <p className="mb-0">Tilføj varenøgleord</p>
                  )}
                </label>
                <i className={editableClass('vendor_products_tags') + ' fas ' + editableSrc('vendor_products_tags')} />
              </div>
            )}
          </div>
        </td>

        <td className="product-item">
          <label>{displayPricesOnMainVariant()}</label>
        </td>

        <td
          className="product-item product-item-editable d-none d-sm-table-cell"
          onClick={() => {
            handleEditing('vendor_products_status');
          }}
        >
          <div className="product-item-container">
            {isEditing && isEditing.key === 'vendor_products_status' ? (
              <>
                <select
                  id="vendor_products_status"
                  aria-label="status"
                  onBlur={handleBlur}
                  value={product?.vendor_products_status}
                  onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value)}
                >
                  <option value="draft">Kladde</option>
                  <option value="publish">Udgiv</option>
                </select>
                <i className={'editable-icon editable-icon-focus fas ' + editableSrc('vendor_products_status')} />
              </>
            ) : (
              <>
                <label className="product-item-editable-label">{getProductStatus()}</label>
                <i className={editableClass('vendor_products_status') + 'fas ' + editableSrc('vendor_products_status')} />
              </>
            )}
          </div>
        </td>

        <td className="product-item d-none d-xl-table-cell">
          <label>{displayStockOnMainVariant()}</label>
        </td>

        <td className="product-item text-center">
          <span className="product-item-edit-btn product-item-remove-btn" onClick={() => handleRemoveProduct(props.product)}>
            <i className="fas fa-trash" />
          </span>
          <span className="product-item-edit-btn" onClick={() => onProductSelect(props.product)}>
            <i className="fas fa-edit" />
          </span>
        </td>
      </tr>
      {props.product.product_variations &&
        props.product.product_variations?.length !== 0 &&
        props.product.product_variations.map((variation, index) => (
          <tr
            key={index}
            className={`${
              variation.vendor_product_variations_id !== undefined && selectedVariations.includes(variation.vendor_product_variations_id)
                ? 'product-table-selected'
                : ''
            } ${showVariations ? '' : 'd-none'} product-table-variable`}
          >
            <td className="product-variable product-item" onClick={() => handleVariationClick(variation)}>
              <div className="edit-custom-checkbox variable-checkbox justify-content-end">
                <label className="align-self-center pt-1">
                  <input
                    type="checkbox"
                    className="me-2 ms-4"
                    onClick={(event) => event.stopPropagation()}
                    disabled={!selectedProducts.some((product) => product.vendor_products_id === props.product.vendor_products_id)}
                    readOnly
                  />
                  <span className="edit-custom-checkbox-check" aria-hidden="true">
                    <i
                      className={(selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id) ? '' : 'hidden ') + 'fas fa-check'}
                    />
                  </span>
                </label>
              </div>
            </td>

            <td className="product-variable product-item">
              {variation.vendor_product_variations_image && variation.vendor_product_variations_image.length !== 0 ? (
                <label>
                  <img height={50} src={variation.vendor_product_variations_image} alt="produkt billede" loading="lazy" />
                </label>
              ) : null}
            </td>

            <td
              className="product-variable product-item product-item-editable d-none d-sm-table-cell"
              onClick={() => {
                handleEditing('vendor_product_variations_sku', variation.vendor_product_variations_id);
              }}
            >
              <div className="multi-line-wrapper product-item-container">
                <div className="d-flex align-items-center">
                  {isEditing && isEditing.key === 'vendor_product_variations_sku' && isEditing.id === variation.vendor_product_variations_id ? (
                    <>
                      <input
                        id="vendor_product_variations_sku"
                        type="text"
                        value={variationProducts?.[index].vendor_product_variations_sku}
                        onBlur={(e) => handleBlur(e, variation)}
                        onKeyDown={(e) => handleKeyDown(e, variation)}
                        onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                        onFocus={(e) => setInputWidth(e.target.value)}
                        style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                        autoFocus
                      ></input>
                      <i
                        className={
                          'editable-icon editable-icon-focus fas fa-' + editableSrc('vendor_product_variations_sku', variation.vendor_product_variations_id)
                        }
                      />
                    </>
                  ) : (
                    <>
                      <div>
                        <label className="product-variable-item-editable-label">{variation.vendor_product_variations_sku}</label>
                        <i
                          className={
                            editableClass('vendor_product_variations_sku', variation.vendor_product_variations_id) +
                            'fas fa-' +
                            editableSrc('vendor_product_variations_sku', variation.vendor_product_variations_id)
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
                <small>{variation.vendor_product_variations_ean}</small>
              </div>
            </td>

            <td className="product-variable product-item">
              <label>
                {variation.vendor_product_variations_attributes}: {variation.vendor_product_variations_attributes_value}
              </label>
            </td>
            <td className="product-variable product-item d-none d-sm-table-cell"></td>
            <td className="product-variable product-item d-none d-sm-table-cell"></td>

            <td className="product-variable product-item product-item-editable d-sm-table-cell">
              <div className="product-item-container">
                <div className="multi-line-wrapper">
                  {variation.vendor_product_variations_sales_price ? (
                    <>
                      {isEditing && isEditing.key === 'vendor_product_variations_price' && isEditing.id === variation.vendor_product_variations_id ? (
                        <input
                          id="vendor_product_variations_price"
                          type="number"
                          value={variationProducts?.[index].vendor_product_variations_price}
                          onBlur={(e) => handleBlur(e, variation)}
                          onKeyDown={(e) => handleKeyDown(e, variation)}
                          onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                          onFocus={(e) => setInputWidth(e.target.value)}
                          style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                          autoFocus
                        />
                      ) : (
                        <label
                          className="product-item-editable-label"
                          onClick={() => {
                            handleEditing('vendor_product_variations_price', variation.vendor_product_variations_id);
                          }}
                        >
                          <s>{numberFormatter.format(variation.vendor_product_variations_price)} DKK</s>
                        </label>
                      )}

                      {isEditing && isEditing.key === 'vendor_product_variations_sales_price' && isEditing.id === variation.vendor_product_variations_id ? (
                        <input
                          id="vendor_product_variations_sales_price"
                          type="number"
                          value={variationProducts?.[index].vendor_product_variations_sales_price}
                          onBlur={(e) => handleBlur(e, variation)}
                          onKeyDown={(e) => handleKeyDown(e, variation)}
                          onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                          onFocus={(e) => setInputWidth(e.target.value)}
                          style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                          autoFocus
                        />
                      ) : (
                        <label
                          className="product-item-editable-label"
                          onClick={() => {
                            handleEditing('vendor_product_variations_sales_price', variation.vendor_product_variations_id);
                          }}
                        >
                          {numberFormatter.format(variation.vendor_product_variations_sales_price)} DKK
                        </label>
                      )}
                    </>
                  ) : (
                    <>
                      {(isEditing && isEditing.key === 'vendor_product_variations_price' && isEditing.id === variation.vendor_product_variations_id) ||
                      (isEditing && isEditing.key === 'vendor_product_variations_sales_price' && isEditing.id === variation.vendor_product_variations_id) ? (
                        <>
                          {isEditing && isEditing.key === 'vendor_product_variations_price' && isEditing.id === variation.vendor_product_variations_id ? (
                            <input
                              id={'vendor_product_variations_price'}
                              type="number"
                              value={variationProducts?.[index].vendor_product_variations_price}
                              onBlur={(e) => handleBlur(e, variation)}
                              onKeyDown={(e) => handleKeyDown(e, variation)}
                              onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                              onFocus={(e) => setInputWidth(e.target.value)}
                              style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                              autoFocus
                            />
                          ) : (
                            <>
                              <label
                                className="product-item-editable-label"
                                onClick={() => {
                                  handleEditing('vendor_product_variations_price', variation.vendor_product_variations_id);
                                }}
                              >
                                <s>{numberFormatter.format(variation.vendor_product_variations_price)} DKK</s>
                              </label>
                              <input
                                id={'vendor_product_variations_sales_price'}
                                type="number"
                                value={variationProducts?.[index].vendor_product_variations_sales_price}
                                onBlur={(e) => handleBlur(e, variation)}
                                onKeyDown={(e) => handleKeyDown(e, variation)}
                                onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                                onFocus={(e) => setInputWidth(e.target.value)}
                                style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                                autoFocus
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <label
                            className="product-item-editable-label"
                            onClick={() => {
                              handleEditing('vendor_product_variations_price', variation.vendor_product_variations_id);
                            }}
                          >
                            {numberFormatter.format(variation.vendor_product_variations_price)} DKK
                          </label>
                          <label
                            className="product-item-editable-label add-sales"
                            onClick={() => {
                              handleEditing('vendor_product_variations_sales_price', variation.vendor_product_variations_id);
                            }}
                          >
                            + Tilføj udsalgspris
                          </label>
                        </>
                      )}
                    </>
                  )}
                </div>
                <i
                  className={
                    editableClass(['vendor_product_variations_price', 'vendor_product_variations_sales_price'], variation.vendor_product_variations_id) +
                    'fas fa-' +
                    editableSrc(['vendor_product_variations_price', 'vendor_product_variations_sales_price'], variation.vendor_product_variations_id)
                  }
                />
              </div>
            </td>

            <td className="product-variable product-item d-none d-sm-table-cell"></td>

            <td
              className="product-variable product-item product-item-editable d-none d-xl-table-cell"
              onClick={() => {
                handleEditing('vendor_product_variations_stock', variation.vendor_product_variations_id);
              }}
            >
              <div className="product-item-container">
                {isEditing && isEditing.key === 'vendor_product_variations_stock' && isEditing.id === variation.vendor_product_variations_id ? (
                  <>
                    <input
                      id="vendor_product_variations_stock"
                      type="number"
                      value={variationProducts?.[index].vendor_product_variations_stock}
                      onBlur={(e) => handleBlur(e, variation)}
                      onKeyDown={(e) => handleKeyDown(e, variation)}
                      onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                      onFocus={(e) => setInputWidth(e.target.value)}
                      style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                      autoFocus
                    ></input>
                    <i
                      className={
                        'editable-icon editable-icon-focus fas fa-' + editableSrc('vendor_product_variations_stock', variation.vendor_product_variations_id)
                      }
                    />
                  </>
                ) : (
                  <>
                    <label className="product-item-editable-label">
                      <span
                        className={variation.vendor_product_variations_stock && variation.vendor_product_variations_stock > 0 ? 'in-stock' : 'out-of-stock'}
                      ></span>
                      {variation.vendor_product_variations_stock} stk.
                    </label>
                    <i
                      className={
                        editableClass('vendor_product_variations_stock', variation.vendor_product_variations_id) +
                        ' fas fa-' +
                        editableSrc('vendor_product_variations_stock', variation.vendor_product_variations_id)
                      }
                    />
                  </>
                )}
              </div>
            </td>

            <td className="product-item text-center">
              <span className="product-item-edit-btn product-item-remove-btn" onClick={() => handleRemoveProduct(props.product, variation)}>
                <i className="fas fa-trash" />
              </span>
            </td>
          </tr>
        ))}
    </>
  );
};

export default EditVariableProducts;
