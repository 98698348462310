import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Modal, Form, Accordion } from 'react-bootstrap';
//Multiselect dropdown
import Select, { OnChangeValue } from 'react-select';

import { SimpleModalProps } from './types';
import { ProductInterface, CategoryInterface, ExtraAttributes, ChildVariation, TagInterface } from '../../../pages/import-products/shared/types';
import { dexieDB } from '../../../pages/import-products/shared/db';

const SimpleProductModal: React.FC<React.PropsWithChildren<SimpleModalProps>> = (props) => {
  const [attributes, setAttributes] = useState<ExtraAttributes[]>([]);
  const [variations, setVariations] = useState<ChildVariation[]>(
    props.productUnderEdit && props.productUnderEdit.product_variations ? props.productUnderEdit.product_variations : [],
  );
  const [productStock, setProductStock] = useState<string>(
    props.productUnderEdit && props.productUnderEdit.vendor_products_stock ? props.productUnderEdit.vendor_products_stock.toString() : '',
  );
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [handleSaving, setHandleSaving] = useState<boolean>(false);

  useEffect(() => {
    setIsEdited(false);
  }, []);

  const updateAttributes = async () => {
    const attributeData: ExtraAttributes[] = [];

    setAttributes([]);

    if (props.productUnderEdit) {
      if (props.productUnderEdit.vendor_products_properties && typeof props.productUnderEdit.vendor_products_properties === 'string') {
        const properties = JSON.parse(props.productUnderEdit.vendor_products_properties);

        for await (const property of properties) {
          for await (const [key, value] of Object.entries(property)) {
            attributeData.push({
              name: key,
              value: value as string,
            });
          }
        }
      }

      if (props.productUnderEdit?.product_variations?.length !== 0) {
        for (const variation of variations) {
          if (variation.vendor_product_variations_properties && typeof variation.vendor_product_variations_properties === 'string') {
            const properties = JSON.parse(variation.vendor_product_variations_properties);

            for await (const property of properties) {
              for await (const [key, value] of Object.entries(property)) {
                if (!attributeData.some((attr) => attr.name === key && attr.value === value)) {
                  attributeData.push({
                    name: key,
                    value: value as string,
                  });
                }
              }
            }
          }
        }
      }
    }
    setAttributes(attributeData);
  };

  useEffect(() => {
    if (handleSaving === true) {
      return;
    }

    updateAttributes();
  }, [props.productUnderEdit, props.isSimpleModalOpen, variations]);

  useEffect(() => {
    if (!props.productUnderEdit) {
      return;
    }

    let ProductStockCount = 0;

    if (productStock) {
      ProductStockCount = Number(productStock);
    }

    props.setProductUnderEdit({
      ...props.productUnderEdit,
      ['vendor_products_stock']: ProductStockCount,
    });
  }, [productStock]);

  useEffect(() => {
    if (!props.productUnderEdit) {
      return;
    }

    setVariations(props.productUnderEdit && props.productUnderEdit.product_variations ? props.productUnderEdit.product_variations : []);

    setProductStock(`${Number(props.productUnderEdit.vendor_products_stock)}`);
  }, [props.productUnderEdit]);

  const handleProductSave = async (product: ProductInterface | null | undefined): Promise<void> => {
    setHandleSaving(true);

    if (!product || !product.vendor_products_id) {
      setHandleSaving(false);
      return;
    }

    const product_variations_properties = attributes.map((attribute) => {
      return { [attribute.name]: attribute.value };
    });

    if (variations && variations.length !== 0) {
      for await (const variation of variations) {
        variation.vendor_product_variations_properties = JSON.stringify(product_variations_properties);
      }
    }

    const updatedProduct = {
      ...product,
      vendor_products_properties: JSON.stringify(product_variations_properties),
      product_variations: product.product_variations ? variations : product.product_variations,
    };

    await dexieDB.editedProducts.update(product.vendor_products_id, updatedProduct);
    props.setIsSimpleModalOpen(false);
    setHandleSaving(false);
    setIsEdited(false);
  };

  const handleProductValueChanged = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>): void => {
    if (!props.productUnderEdit) {
      return;
    }

    setIsEdited(true);

    props.setProductUnderEdit({
      ...props.productUnderEdit,
      [event.target.id]: event.target.value,
    });
  };

  const handleVariationValueChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, selectedVariation: ChildVariation) => {
    const updatedVariations = variations.map((variation) =>
      variation.vendor_product_variations_id === selectedVariation.vendor_product_variations_id
        ? { ...variation, [event.target.id]: event.target.value }
        : variation,
    );

    setIsEdited(true);

    setVariations(updatedVariations);
  };

  const handleEditCategories = (newValue: OnChangeValue<CategoryInterface, true>): void => {
    if (!props.productUnderEdit) {
      return;
    }

    setIsEdited(true);

    props.setProductUnderEdit({
      ...props.productUnderEdit,
      vendor_products_category: newValue as CategoryInterface[],
    });
  };

  const handleEditTags = (newValue: OnChangeValue<TagInterface, true>): void => {
    if (!props.productUnderEdit) {
      return;
    }

    setIsEdited(true);

    props.setProductUnderEdit({
      ...props.productUnderEdit,
      vendor_products_tags: newValue as TagInterface[],
    });
  };

  const handleAddAttribute = () => {
    const newAttribute = { name: '', value: '' };
    setAttributes((currentAttributes) => [...currentAttributes, newAttribute]);
    setIsEdited(true);
  };

  const handleRemoveAttribute = (index: number) => {
    setAttributes((currentAttributes) => currentAttributes.filter((_, currentIndex) => currentIndex !== index));
    setIsEdited(true);
  };

  const handleAttributeChange = (index: number, key: keyof ExtraAttributes, value: string) => {
    setAttributes((currentAttributes) => currentAttributes.map((attr, i) => (i === index ? { ...attr, [key]: value } : attr)));
    setIsEdited(true);
  };

  const getProductStatus = (): string => {
    if (!props.productUnderEdit || !props.productUnderEdit.vendor_products_status) {
      return 'draft';
    }

    return props.productUnderEdit.vendor_products_status;
  };

  const getProductSalesPrice = (): number => {
    if (!props.productUnderEdit || !props.productUnderEdit.vendor_products_sales_price) {
      return 0;
    }

    return props.productUnderEdit.vendor_products_sales_price;
  };

  const getProductCostPrice = (): number => {
    if (!props.productUnderEdit || !props.productUnderEdit.vendor_products_cost_price) {
      return 0;
    }

    return props.productUnderEdit.vendor_products_cost_price;
  };

  const getStockStatus = (): 'instock' | 'outofstock' => {
    if (props.productUnderEdit?.vendor_products_stock === 0) {
      return 'outofstock';
    } else {
      return 'instock';
    }
  };

  const getProductDate = (date?: Date) => {
    if (date) {
      const productDate = new Date(date);

      const formattedDate = `${productDate.getDate()}/${productDate.getMonth()}/${productDate.getFullYear()} ${productDate.getHours()}:${productDate.getMinutes()}`;
      return formattedDate;
    }
  };

  const handleModalAction = (action: string) => {
    if (isEdited) {
      const confirmAction = confirm(
        'Du har foretaget ændringer, der ikke er gemt. Hvis du fortsætter, går dine ændringer tabt. Vil du fortsætte uden at gemme?',
      );
      if (!confirmAction) {
        return;
      }
      setIsEdited(false);
    }

    switch (action) {
      case 'prevProduct':
        props.setProductUnderEdit(props.prevAndNext.prevProduct);
        break;
      case 'nextProduct':
        props.setProductUnderEdit(props.prevAndNext.nextProduct);
        break;
      case 'closeModal':
        props.setIsSimpleModalOpen(false);
        break;
      default:
        return;
    }
  };

  return (
    <Modal show={props.isSimpleModalOpen} className="edit-product-modal" backdrop="static" keyboard={false}>
      <Modal.Header className="justify-content-between">
        <Modal.Title className="edit-product-modal-header">
          <h1>Rediger produkt</h1>
          <h2>Indsæt dine egne værdier i felterne nedenfor</h2>
        </Modal.Title>
        <Button className="edit-product-modal-close" variant="secondary" onClick={() => handleModalAction('closeModal')}>
          <i className="fas fa-xmark" />
        </Button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Row className="d-flex">
            <Col className="edit-product-modal-nav-btn">
              <button className={!props.prevAndNext.prevProduct ? 'hidden' : ''} type="button" onClick={() => handleModalAction('prevProduct')}>
                <i className="fas fa-chevron-left" />
              </button>
            </Col>
            <Col>
              <Row className="edit-product-modal-body">
                <Col>
                  {props.productUnderEdit && props.productUnderEdit.vendor_products_image && props.productUnderEdit.vendor_products_image.length !== 0 ? (
                    <img className="edit-product-modal-body-image" src={props.productUnderEdit.vendor_products_image} alt="produkt billede" loading="lazy" />
                  ) : null}
                </Col>
                <Col>
                  <Row>
                    <Form.Group controlId={'vendors_name'} className="edit-product-modal-body-input">
                      <Form.Label>Forhandler</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Forhandler"
                        value={props.productUnderEdit?.vendors_name}
                        onChange={handleProductValueChanged}
                        disabled
                      />
                    </Form.Group>
                  </Row>

                  {props.productUnderEdit?.product_variations?.length === 0 && (
                    <Row className="gap-2">
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_sku'} className="edit-product-modal-body-input">
                            <Form.Label>Varenummer / SKU</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Varenummer"
                              value={props.productUnderEdit?.vendor_products_sku}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_ean'} className="edit-product-modal-body-input">
                            <Form.Label>Stregkode / EAN</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Stregkode / EAN"
                              value={props.productUnderEdit?.vendor_products_ean}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Form.Group controlId={'vendor_products_name'} className="edit-product-modal-body-input">
                      <Form.Label>Produktnavn</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Indtast produktnavn"
                        value={props.productUnderEdit?.vendor_products_name}
                        onChange={handleProductValueChanged}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group controlId={'vendor_products_slug'} className="edit-product-modal-body-input">
                      <Form.Label>URL / Slug</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Indtast produkt slug"
                        value={props.productUnderEdit?.vendor_products_slug}
                        onChange={handleProductValueChanged}
                      />
                    </Form.Group>
                  </Row>

                  {/* Price */}
                  {props.productUnderEdit?.product_variations?.length === 0 && (
                    <Row className="gap-2">
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_price'} className="edit-product-modal-body-input">
                            <Form.Label>Vejl. pris </Form.Label>
                            <Form.Control
                              type="number"
                              min={0}
                              step={50}
                              placeholder="Indtast vejledende pris"
                              value={props.productUnderEdit?.vendor_products_price}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_sales_price'} className="edit-product-modal-body-input">
                            <Form.Label>Tilbudspris</Form.Label>
                            <Form.Control
                              type="number"
                              min={0}
                              step={50}
                              placeholder="Indtast tilbudspris"
                              value={getProductSalesPrice()}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_cost_price'} className="edit-product-modal-body-input">
                            <Form.Label>Kostpris</Form.Label>
                            <Form.Control
                              type="number"
                              min={0}
                              step={50}
                              placeholder="Indtast kostpris"
                              value={getProductCostPrice()}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                      <Form.Text className="text-muted m-0">* Priser opgivet i € er ganget med kurs 7,5</Form.Text>
                    </Row>
                  )}

                  {/* Status + Stock + Weight */}
                  <Row className="gap-2">
                    {props.productUnderEdit?.product_variations?.length === 0 && (
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_stock'} className="edit-product-modal-body-input">
                            <Form.Label>Lager</Form.Label>
                            <div className="d-flex stock-input">
                              <span className={getStockStatus() === 'instock' ? 'in-stock' : 'out-of-stock'}></span>
                              <Form.Control
                                type="number"
                                min={0}
                                placeholder="Indtast lagerbeholdning"
                                value={productStock}
                                onChange={(event) => setProductStock(event.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </Row>
                      </Col>
                    )}
                    <Col>
                      <Row>
                        <Form.Group controlId={'vendor_products_status'} className="edit-product-modal-body-input">
                          <Form.Label>Status</Form.Label>
                          <Form.Select aria-label="status" value={getProductStatus()} onChange={handleProductValueChanged}>
                            <option value="draft">Kladde</option>
                            <option value="publish">Udgiv</option>
                          </Form.Select>
                        </Form.Group>
                      </Row>
                    </Col>
                    {props.productUnderEdit?.product_variations?.length === 0 && (
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_weight'} className="edit-product-modal-body-input">
                            <Form.Label>Vægt</Form.Label>
                            <Form.Control
                              type="number"
                              min={0}
                              step={5}
                              placeholder="Indtast vægt"
                              value={props.productUnderEdit?.vendor_products_weight}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                    )}
                  </Row>

                  {/* Description */}
                  <Row>
                    <Form.Group controlId={'vendor_products_excerpt'} className="edit-product-modal-body-input">
                      <Form.Label>Kort beskrivelse</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Indtast kort beskrivelse"
                        value={props.productUnderEdit?.vendor_products_excerpt}
                        onChange={handleProductValueChanged}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group controlId={'vendor_products_content'} className="edit-product-modal-body-input">
                      <Form.Label>Beskrivelse</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Indtast beskrivelse"
                        value={props.productUnderEdit?.vendor_products_content}
                        onChange={handleProductValueChanged}
                      />
                    </Form.Group>
                  </Row>

                  {/* Category */}
                  <Row>
                    <Form.Group controlId={'vendor_products_category'} className="edit-product-modal-body-input">
                      <Form.Label>Kategori</Form.Label>
                      <Form.Text className="d-block text-muted">Hvis en kategori ikke findes, skal du oprette den i webshoppen</Form.Text>
                      <Select
                        id="vendor_products_category"
                        className="modal-select"
                        classNamePrefix="modal-select"
                        options={props.webshopCategories}
                        placeholder="Vælg kategori"
                        isSearchable={true}
                        isMulti={true}
                        getOptionValue={(option: CategoryInterface) => option.id}
                        getOptionLabel={(option: CategoryInterface) => `${option.name}`}
                        onChange={handleEditCategories}
                        noOptionsMessage={() => `${'Ingen kategorier'}`}
                        value={props.productUnderEdit?.vendor_products_category as CategoryInterface[]}
                      />
                    </Form.Group>
                  </Row>

                  {/* Tags */}
                  <Row>
                    <Form.Group controlId={'vendor_products_tags'} className="edit-product-modal-body-input">
                      <Form.Label>Varenøgleord</Form.Label>
                      <Form.Text className="d-block text-muted">Hvis nøgleordet ikke findes, skal du oprette det i webshoppen</Form.Text>
                      <Select
                        id="vendor_products_tags"
                        className="modal-select"
                        classNamePrefix="modal-select"
                        options={props.webshopTags}
                        placeholder="Vælg nøgleord"
                        isSearchable={true}
                        isMulti={true}
                        getOptionValue={(option: TagInterface) => option.id}
                        getOptionLabel={(option: TagInterface) => `${option.name}`}
                        onChange={handleEditTags}
                        noOptionsMessage={() => `${'Ingen nøgleord'}`}
                        value={props.productUnderEdit?.vendor_products_tags as TagInterface[]}
                      />
                    </Form.Group>
                  </Row>

                  {/* Attributes */}
                  <>
                    <Row className="edit-product-modal-body-input">
                      <Form.Label className="p-0">Opret Attributter</Form.Label>
                      {attributes.map((attribute, index) => (
                        <Row key={index} className="gap-2">
                          <Col className="p-0">
                            <Form.Group controlId={`attribute-name-${index}`} className="edit-product-modal-body-input">
                              <Form.Control
                                type="text"
                                placeholder="Indtast navn"
                                value={attribute.name}
                                onChange={(e) => handleAttributeChange(index, 'name', e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="p-0">
                            <Form.Group controlId={`attribute-value-${index}`} className="edit-product-modal-body-input">
                              <Form.Control
                                type="text"
                                placeholder="Indtast værdi"
                                value={attribute.value}
                                onChange={(e) => handleAttributeChange(index, 'value', e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <div className="edit-product-modal-remove">
                            <Button variant="secondary" onClick={() => handleRemoveAttribute(index)}>
                              <i className="fas fa-xmark" />
                            </Button>
                          </div>
                        </Row>
                      ))}

                      <Row>
                        <Col xs={12} className="d-flex justify-content-left edit-product-modal-body-input">
                          <Button className="edit-product-modal-body-input-btn" onClick={handleAddAttribute}>
                            Tilføj attribut
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  </>
                </Col>
              </Row>
            </Col>
            <Col className="edit-product-modal-nav-btn">
              <button className={!props.prevAndNext.nextProduct ? 'hidden' : ''} type="button" onClick={() => handleModalAction('nextProduct')}>
                <i className="fas fa-chevron-right" />
              </button>
            </Col>
          </Row>
          <Row className="edit-product-modal-variation">
            {props.productUnderEdit?.product_variations?.length !== 0 && (
              <Col key="variant-accordion" className="p-0">
                <Accordion>
                  {variations.map((variation, index) => (
                    <Accordion.Item key={index} eventKey={index.toString()}>
                      <Accordion.Header>
                        {variation.vendor_product_variations_image && variation.vendor_product_variations_image.length !== 0 ? (
                          <img
                            className="edit-product-modal-variation-image"
                            src={variation.vendor_product_variations_image}
                            alt="produkt billede"
                            loading="lazy"
                          />
                        ) : (
                          <div className="edit-product-modal-variation-image"></div>
                        )}
                        <p className="m-0">
                          {variation.vendor_product_variations_attributes}: {variation.vendor_product_variations_attributes_value}
                        </p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Col>
                          <Row className="gap-2 px-2">
                            <Col>
                              <Row>
                                <Form.Group className="edit-product-modal-body-input">
                                  <Form.Label>Varenummer / SKU</Form.Label>
                                  <input
                                    id="vendor_product_variations_sku"
                                    className="form-control"
                                    type="text"
                                    placeholder="Varenummer"
                                    value={variation.vendor_product_variations_sku}
                                    onChange={(e) => handleVariationValueChanged(e, variation)}
                                  />
                                </Form.Group>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Form.Group className="edit-product-modal-body-input">
                                  <Form.Label>Stregkode / EAN</Form.Label>
                                  <input
                                    id="vendor_product_variations_ean"
                                    className="form-control"
                                    type="text"
                                    placeholder="Stregkode / EAN"
                                    value={variation.vendor_product_variations_ean}
                                    onChange={(e) => handleVariationValueChanged(e, variation)}
                                  />
                                </Form.Group>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="gap-2 px-2">
                            <Col>
                              <Row>
                                <Form.Group className="edit-product-modal-body-input">
                                  <Form.Label>Vejl. pris </Form.Label>
                                  <input
                                    id="vendor_product_variations_price"
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    step={50}
                                    placeholder="Indtast vejledende pris"
                                    value={variation.vendor_product_variations_price}
                                    onChange={(e) => handleVariationValueChanged(e, variation)}
                                  />
                                </Form.Group>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Form.Group className="edit-product-modal-body-input">
                                  <Form.Label>Tilbudspris</Form.Label>
                                  <input
                                    id="vendor_product_variations_sales_price"
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    step={50}
                                    placeholder="Indtast tilbudspris"
                                    value={variation.vendor_product_variations_sales_price ? variation.vendor_product_variations_sales_price : 0}
                                    onChange={(e) => handleVariationValueChanged(e, variation)}
                                  />
                                </Form.Group>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Form.Group className="edit-product-modal-body-input">
                                  <Form.Label>Kostpris</Form.Label>
                                  <input
                                    id="vendor_product_variations_cost_price"
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    step={50}
                                    placeholder="Indtast kostpris"
                                    value={variation.vendor_product_variations_cost_price ? variation.vendor_product_variations_cost_price : 0}
                                    onChange={(e) => handleVariationValueChanged(e, variation)}
                                  />
                                </Form.Group>
                              </Row>
                            </Col>
                          </Row>
                          <Form.Text className="text-muted m-0 px-2">* Priser opgivet i € er ganget med kurs 7,5</Form.Text>
                          <Row className="gap-2 px-2">
                            <Col>
                              <Row>
                                <Form.Group className="edit-product-modal-body-input">
                                  <Form.Label>Lager</Form.Label>
                                  <div className="d-flex stock-input">
                                    <span
                                      className={
                                        variation.vendor_product_variations_stock && variation.vendor_product_variations_stock > 0 ? 'in-stock' : 'out-of-stock'
                                      }
                                    ></span>
                                    <input
                                      id="vendor_product_variations_stock"
                                      className="form-control"
                                      type="number"
                                      min={0}
                                      placeholder="Indtast lagerbeholdning"
                                      value={variation.vendor_product_variations_stock}
                                      onChange={(e) => handleVariationValueChanged(e, variation)}
                                    />
                                  </div>
                                </Form.Group>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Form.Group className="edit-product-modal-body-input">
                                  <Form.Label>Vægt</Form.Label>
                                  <input
                                    id="vendor_product_variations_weight"
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    step={5}
                                    placeholder="Indtast vægt"
                                    value={variation.vendor_product_variations_weight}
                                    onChange={(e) => handleVariationValueChanged(e, variation)}
                                  />
                                </Form.Group>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="px-2">
                            <Col>
                              <Row>
                                <Form.Group className="edit-product-modal-body-input">
                                  <Form.Label>{variation.vendor_product_variations_attributes}</Form.Label>
                                  <input
                                    id="vendor_product_variations_attributes_value"
                                    className="form-control"
                                    type="text"
                                    placeholder="Indtast værdi"
                                    value={variation.vendor_product_variations_attributes_value}
                                    onChange={(e) => handleVariationValueChanged(e, variation)}
                                  />
                                </Form.Group>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="edit-product-modal-footer">
            {props.productUnderEdit?.vendor_products_created && <p>Oprettet: {getProductDate(props.productUnderEdit?.vendor_products_created)}</p>}
            {props.productUnderEdit?.vendor_products_updated && <p>Senest opdateret: {getProductDate(props.productUnderEdit?.vendor_products_updated)}</p>}
          </div>
          <div className="edit-product-modal-buttons">
            <Button
              className="button-orange"
              onClick={() => {
                setIsEdited(false);
                props.setIsSimpleModalOpen(false);
              }}
            >
              Afbryd
            </Button>
            <Button onClick={() => handleProductSave(props.productUnderEdit)}>Gem</Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SimpleProductModal;
